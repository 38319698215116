import { KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  const keycloakOptions: KeycloakOptions = {
    config: { ...environment.keycloak },
    initOptions: {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      token: localStorage.getItem('token') as any,
      refreshToken: localStorage.getItem('refreshToken') as any,
    },
  };

  return () => keycloak.init(keycloakOptions);
}
