export const environment = {
  production: true,
  baseURL: 'https://admin.api.jecrm.org/admin',
  socketURL: 'https://admin.api.jecrm.org',
  keycloak: {
    url: 'https://sso.jecrm.org',
    realm: 'crm-realm',
    clientId: 'crm-client',
  },
  specialDomains: [
    'dev',
    'demo',
    'api',
    'dev-api',
    'demo-api',
    'sso',
    'dev-sso',
    'demo-sso',
    'tb',
    'dev-tb',
    'demo-tb',
  ],
};
