import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AdminLogService } from 'src/app/services/admin-log.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent {
  constructor(
    private readonly router: Router,
    private readonly keycloakService: KeycloakService,
    private adminLogService: AdminLogService
  ) { }

  async ngOnInit() {
    if (await this.keycloakService.isLoggedIn()) {
      await this.adminLogService.login().subscribe({
        next: (res: { data: any; }) => {
          const { data } = res;
          console.log(data);
          
        },
        error: (err: any) => {
          console.log(err);
        },
      });
      this.router.navigate(['/admin']);
    } else {
      await this.keycloakService.login().then();
    }
  }
}
