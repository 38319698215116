import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IParams } from '../model/http';


export const initCompanyQueryParams: IParams = {
  sort: '',
  order: '',
  search: '',
  take: 10,
  page: 1,
};

@Injectable({
  providedIn: 'root',
})
export class AdminLogService {
  public queryParams = { ...initCompanyQueryParams };

  constructor(
    private readonly http: HttpClient
  ) { }

  public login() {
    return this.http.post<any>(environment.baseURL + '/log/sign-in', {});
  }

  public getAdminLogs(params: IParams) {
    const { sort, order, search, take, page } = params;
    this.queryParams = {
      sort: sort || typeof (sort) === 'string' ? sort : this.queryParams.sort,
      order: order || typeof (order) === 'string' ? order : this.queryParams.order,
      search: search || typeof (search) === 'string' ? search : this.queryParams.search,
      take: take || this.queryParams.take,
      page: page || this.queryParams.page,
    };
    return this.http.get<any>(environment.baseURL + '/log', { params: this.queryParams });
  }
}
